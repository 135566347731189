export const RadioError = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="Icons/RadioError/16px">
      <path
        id="Subtract"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM5.13131 12L8 9.13131L10.8283 12L12 10.8687L9.13131 8L12 5.13131L10.8687 4L8 6.86869L5.13131 4L4 5.13131L6.86869 8L4 10.8687L5.13131 12Z"
        fill="#F44336"
      />
    </g>
  </svg>
);

export const RadioErrorMuted = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="Icons/Error/16px">
      <path
        id="Vector"
        d="M7.99967 11.3334C8.18856 11.3334 8.3469 11.2695 8.47467 11.1417C8.60245 11.0139 8.66634 10.8556 8.66634 10.6667C8.66634 10.4778 8.60245 10.3195 8.47467 10.1917C8.3469 10.0639 8.18856 10 7.99967 10C7.81079 10 7.65245 10.0639 7.52467 10.1917C7.3969 10.3195 7.33301 10.4778 7.33301 10.6667C7.33301 10.8556 7.3969 11.0139 7.52467 11.1417C7.65245 11.2695 7.81079 11.3334 7.99967 11.3334ZM7.33301 8.66671H8.66634V4.66671H7.33301V8.66671ZM7.99967 14.6667C7.07745 14.6667 6.21079 14.4917 5.39967 14.1417C4.58856 13.7917 3.88301 13.3167 3.28301 12.7167C2.68301 12.1167 2.20801 11.4112 1.85801 10.6C1.50801 9.78893 1.33301 8.92226 1.33301 8.00004C1.33301 7.07782 1.50801 6.21115 1.85801 5.40004C2.20801 4.58893 2.68301 3.88337 3.28301 3.28337C3.88301 2.68337 4.58856 2.20837 5.39967 1.85837C6.21079 1.50837 7.07745 1.33337 7.99967 1.33337C8.9219 1.33337 9.78856 1.50837 10.5997 1.85837C11.4108 2.20837 12.1163 2.68337 12.7163 3.28337C13.3163 3.88337 13.7913 4.58893 14.1413 5.40004C14.4913 6.21115 14.6663 7.07782 14.6663 8.00004C14.6663 8.92226 14.4913 9.78893 14.1413 10.6C13.7913 11.4112 13.3163 12.1167 12.7163 12.7167C12.1163 13.3167 11.4108 13.7917 10.5997 14.1417C9.78856 14.4917 8.9219 14.6667 7.99967 14.6667Z"
        fill="#C5C5C5"
      />
    </g>
  </svg>
);

export const RadioCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g id="Icons/RadioCheck/16px">
      <path
        id="Vector"
        d="M7.06732 11.0673L11.7673 6.36732L10.834 5.43398L7.06732 9.20065L5.16732 7.30065L4.23398 8.23398L7.06732 11.0673ZM8.00065 14.6673C7.07843 14.6673 6.21176 14.4923 5.40065 14.1423C4.58954 13.7923 3.88398 13.3173 3.28398 12.7173C2.68398 12.1173 2.20898 11.4118 1.85898 10.6007C1.50898 9.78954 1.33398 8.92287 1.33398 8.00065C1.33398 7.07843 1.50898 6.21176 1.85898 5.40065C2.20898 4.58954 2.68398 3.88398 3.28398 3.28398C3.88398 2.68398 4.58954 2.20898 5.40065 1.85898C6.21176 1.50898 7.07843 1.33398 8.00065 1.33398C8.92287 1.33398 9.78954 1.50898 10.6007 1.85898C11.4118 2.20898 12.1173 2.68398 12.7173 3.28398C13.3173 3.88398 13.7923 4.58954 14.1423 5.40065C14.4923 6.21176 14.6673 7.07843 14.6673 8.00065C14.6673 8.92287 14.4923 9.78954 14.1423 10.6007C13.7923 11.4118 13.3173 12.1173 12.7173 12.7173C12.1173 13.3173 11.4118 13.7923 10.6007 14.1423C9.78954 14.4923 8.92287 14.6673 8.00065 14.6673Z"
        fill="#4CAF50"
      />
    </g>
  </svg>
);
