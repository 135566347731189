import type * as React from "react";
import { useState } from "react";
import { useFormContext } from "remix-validated-form";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  useFormField,
} from "~/components/forms/Form";
import { EyeIconActive, EyeIconInactive } from "~/components/ui/eye-icon";
import { Input } from "~/components/ui/input";
import {
  RadioCheck,
  RadioError,
  RadioErrorMuted,
} from "~/components/ui/radio-icon";
import Text from "~/components/ui/text";
import { passwordRequirements } from "~/lib/schemas/validations";

const getIcon = (touched: boolean, hasErrors: boolean) => {
  if (!touched) {
    return <RadioErrorMuted />;
  }

  if (hasErrors) {
    return <RadioError />;
  }

  return <RadioCheck />;
};

const PasswordValidations = () => {
  const { name } = useFormField();
  const { getValues } = useFormContext();

  const value = getValues().get(name)?.toString() || "";

  return (
    <div className="flex flex-col gap-1.5 pt-4">
      {passwordRequirements.map(({ message, pattern }) => (
        <div key={message} className="flex items-center gap-1.5 text-xs">
          <div className="">{getIcon(value !== "", !value.match(pattern))}</div>

          <Text className="text-xs leading-none">{message}</Text>
        </div>
      ))}
    </div>
  );
};

const ValidationPasswordField = ({
  name,
  label,
  description,
  placeholder,
  showRequirements,
  requirements,
  type: _type, //ignore the type and default to password
  ...props
}: {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  showRequirements?: boolean;
  requirements?: string[];
  type?: "password";
} & Omit<React.ComponentProps<typeof Input>, "type">) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormField validationBehavior="onChange" name={name}>
      <FormItem>
        {label && (
          <FormLabel className="text-sm font-semibold">{label}</FormLabel>
        )}
        <div className="relative">
          <FormControl>
            <Input
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
              {...props}
            />
          </FormControl>

          <button
            type="button"
            className="absolute inset-y-0 right-0 mr-4 flex items-center"
            onClick={() => setShowPassword(prev => !prev)}
          >
            {showPassword ? <EyeIconInactive /> : <EyeIconActive />}
          </button>
        </div>
        {description && <FormDescription>{description}</FormDescription>}
        <PasswordValidations />
      </FormItem>
    </FormField>
  );
};
export default ValidationPasswordField;
